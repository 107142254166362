<template>
  <div class="app-container centerParams" id="centerParams">
    <p>
      业务描述：
      普通用户A在商户B后台创建交易单，选择本API作为交易通道，商户调用本接口创建交易单，接口返回交易单数据，商户可以选择自己制作前端页面展示给用户A，或者采用API平台的交易页面（在pay_jump_url属性中）展示给用户A，用户A打开支付页面上的支付方式，拉起支付平台完成交易业务，API后台C在完成交易后，回调交易信息给商户后台。
    </p>
    <p>接口地址：https://api.ydfpay.com/pay/addOrder</p>
    <p>数据格式：json</p>
    <p>Content-Type：application/json</p>
    <p>HTTP方法：post</p>
    <p>编码：utf-8</p>

    <el-row :gutter="20">
      <el-col :span="24" class="flag">
        <h3 @click="requiredFlag = !requiredFlag">必填项
          <i v-show="requiredFlag" class="el-icon-arrow-down"></i>
          <i v-show="!requiredFlag" class="el-icon-arrow-up"></i>
        </h3>
      </el-col>
      <el-col :span="24" v-show="requiredFlag">
        <el-form :rules="rules" ref="dataForm" label-position="left" label-width="0" :model="dataForm">
          <el-form-item label="" prop="appId">
            <span class="title"><b>*</b>appId</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：商户应用编号</div>
                <div>类型：字符串</div>
                <div>说明：由商户平台注册创建应用获取</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.appId" size="small"></el-input>
          </el-form-item>

          <el-form-item label="" prop="appSecret">
            <span class="title"><b>*</b>appSecret</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：商户应用密钥</div>
                <div>类型：字符串</div>
                <div>说明：在商户平台-应用管理-查看详情-查看密钥里获取</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-popover placement="top-start" title="" width="220" trigger="hover">
              <div>
                <div style="color:red">注意：此参数只参与签名{sign}计算，不要写入请求信息里</div>
              </div>
              <i class="el-icon-warning" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.appSecret" size="small"></el-input>
          </el-form-item>

          <el-form-item label="" prop="mchOrderNo">
            <span class="title"><b>*</b>mchOrderNo</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：商户订单号</div>
                <div>类型：字符串</div>
                <div>说明：商户应确保在自己的系统内唯一</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.mchOrderNo" size="small"></el-input>
          </el-form-item>

          <el-form-item label="" prop="amount">
            <span class="title"><b>*</b>amount</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：支付金额</div>
                <div>类型：字符串</div>
                <div>说明：最低金额30.00，可以带小数（保留2位小数）</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.amount" size="small"></el-input>
          </el-form-item>

          <el-form-item label="" prop="currency">
            <span class="title"><b>*</b>currency</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：币种</div>
                <div>类型：字符串</div>
                <div>说明：详见订单交易币种枚举类型（currency=CRYPTO，则amount单位为：美金USD）</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.currency" size="small"></el-input>
          </el-form-item>

          <el-form-item label="" prop="notifyUrl">
            <span class="title"><b>*</b>notifyUrl</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：回调地址</div>
                <div>类型：字符串</div>
                <div>说明：普通用户支付成功后，由API回调商户的地址，采用HTTP POST JSON方式（如果没有提供，API将不会回调）</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.notifyUrl" disabled size="small"></el-input>
          </el-form-item>

          <el-form-item label="" prop="successJumpUrl">
            <span class="title"><b>*</b>successJumpUrl</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：支付成功前端跳转地址</div>
                <div>类型：字符串</div>
                <div>说明：支付成功前端跳转地址</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.successJumpUrl" disabled size="small"></el-input>
          </el-form-item>

          <el-form-item label="" prop="failJumpUrl">
            <span class="title"><b>*</b>failJumpUrl</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：支付失败前端跳转地址</div>
                <div>类型：字符串</div>
                <div>说明：支付失败前端跳转地址</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.failJumpUrl" disabled size="small"></el-input>
          </el-form-item>

          <el-form-item label="" prop="sign">
            <span class="title"><b>*</b>sign</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：签名</div>
                <div>类型：字符串</div>
                <div>说明：签名算法sign=MD5({appId}{mchOrderNo}{amount}{notifyUrl}{appSecret})</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.sign" size="small"></el-input>
          </el-form-item>

          <el-form-item label="" prop="body">
            <span class="title"><b>*</b>body</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：商品名称/订单标题</div>
                <div>类型：字符串</div>
                <div>说明：商品名称/订单标题</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.body" size="small"></el-input>
          </el-form-item>



        </el-form>

      </el-col>
    </el-row>


    <el-row :gutter="20">
      <el-col :span="24">
        <h3>选填项</h3>
      </el-col>
      <el-col :span="24">
        <el-form label-position="left" label-width="0" :model="dataForm">
          <el-form-item label="" prop="lang">
            <span class="title">lang</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：语言</div>
                <div>类型：字符串</div>
                <div>说明：可选项为：zh-cn（简体中文），zh-hk（繁體中文），en-us（English）</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.lang" size="small"></el-input>
            <!-- <el-select style="width:100%" v-model="dataForm.type" placeholder="请选择……" size="mini">
              <el-option label="zh-cn" value="zh-cn"></el-option>
              <el-option label="zh-hk" value="zh-hk"></el-option>
              <el-option label="en-us" value="en-us"></el-option>
            </el-select> -->

          </el-form-item>

          <el-form-item label="" prop="version">
            <span class="title">version</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：版本号</div>
                <div>类型：字符串</div>
                <div>说明：( 1.0, 2.0 )-选填 2023.07.12之后的版本-填写2.0，之前-填写1.0 【默认：2.0】</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.version" disabled size="small"></el-input>
          </el-form-item>

          <el-form-item label="" prop="channelDisplay">
            <span class="title">channelDisplay</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：渠道展示代号</div>
                <div>类型：字符串</div>
                <div>说明：详见交易渠道展示代号枚举类型（可多个值，用英文逗号隔开，例如：DiDaPay,USDT）</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.channelDisplay" size="small"></el-input>
          </el-form-item>

          <el-form-item label="" prop="usdtChannel">
            <span class="title">usdtChannel</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：USDT渠道代号</div>
                <div>类型：字符串</div>
                <div>说明：详见USDT渠道枚举类型</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.usdtChannel" size="small"></el-input>
          </el-form-item>

          <el-form-item label="" prop="notifyFormat">
            <span class="title">notifyFormat</span>
            <el-popover placement="top-start" title="" width="200" trigger="hover">
              <div>
                <div>名称：回调通知格式</div>
                <div>类型：字符串</div>
                <div>说明：（选填）：FORM-DATA、JSON （默认：FORM-DATA）</div>
              </div>
              <i class="el-icon-question" slot="reference"></i>
            </el-popover>
            <el-input clearable placeholder="请输入" v-model="dataForm.notifyFormat" size="small"></el-input>
          </el-form-item>






        </el-form>

      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col>
        <el-button size="small" @click="scrollToTop">返回顶部</el-button>
        <el-button size="small" @click="emptyData">清空</el-button>
        <el-button size="small" @click="resourceAdd" type="primary">发起调用</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import w_md5 from '@/common/w_md5'
import config from "@/api/config";
import axios from "axios";
export default {
  name: "centerParams",
  data() {
    return {
      requiredFlag: true,
      dataForm: {
        appId: "202404120393878629",
        appSecret: "41e4c21d2ccef2e7cfbbdad5acec3bf9",
        mchOrderNo: "202404120393878629",
        amount: "30.00",
        currency: "INR",
        notifyUrl: "https://test.api.ydfpay.com/pay/suerPayNotify2",
        successJumpUrl: "https://test.app.ydfpay.com/#/pages/success",
        failJumpUrl: "https://test.app.ydfpay.com/#/pages/fail",
        sign: "kljkll4fdsf1522sf2454f2ht54jhg1f7f7we97rtiu4u5jng",
        body: "xx的支付订单",
        lang: "zh-cn",
        version: "2.0",
        channelDisplay: "",
        usdtChannel: "",
        notifyFormat: "FORM-DATA"
      },
      rules: {
        appId: [{ required: true, message: '请输入appId', trigger: ["blur"] },],
        appSecret: [{ required: true, message: '请输入appSecret', trigger: ["blur"] },],
        mchOrderNo: [{ required: true, message: '请输入mchOrderNo', trigger: ["blur"] },],
        amount: [{ required: true, message: '请输入amount', trigger: ["blur"] },],
        currency: [{ required: true, message: '请输入currency', trigger: ["blur"] },],
        notifyUrl: [{ required: true, message: '请输入notifyUrl', trigger: ["blur"] },],
        successJumpUrl: [{ required: true, message: '请输入successJumpUrl', trigger: ["blur"] },],
        failJumpUrl: [{ required: true, message: '请输入failJumpUrl', trigger: ["blur"] },],
        sign: [{ required: true, message: '请输入sign', trigger: ["blur"] },],
        body: [{ required: true, message: '请输入body', trigger: ["blur"] },]
      },
      descripData: `
      <p>如果是成功返回，则code=1，如果出错，则code不等于1，msg为具体错误信息。</p>
      <p>正常返回后，交易单详情为JSON字符串，请将data字符串反序列化为支付订单类 进行业务操作，该类所有属性均为字符串类型，请商户按照自己业务类型进行数据转换。</p>
      <p>其中pay_jump_url 属性是api平台提供的支付页面，商户可直接导航用户浏览器打开该地址。</p>
      <br>
      <h4>支付成功后回调：</h4>
      <p>在普通用户打开支付页面，拉起支付平台支付成功后，API会向支付单中的notifyUrl地址进行HTTP POST 调用，发送FORM-DATA格式数据给商户后台，数据格式类为 PayOrderDto。 商户服务器业务逻辑处理完成后，应当响应返回纯字符串 success 后，将不再通知；<p>
      <p>商户务必根据pay_state订单状态属性判断订单是否支付成功，例如pay_state=1的表示已经支付 pay_state 状态 请查看 上文 支付订单状态枚举类型。<br>
      商户可验证签名和回调签名是否正确，同时为了提供安全性可将API服务器地址IP加入自己的程序中做数据来源IP验证。<p>
      `
    };
  },

  created() {
    this.init()
  },
  watch: {

  },
  methods: {
    init() {
      this.dataForm.mchOrderNo = "test" + Date.now()
      this.dataForm.sign = this.getSign(this.dataForm.appId + this.dataForm.mchOrderNo + this.dataForm.amount + this.dataForm.notifyUrl + this.dataForm.appSecret)
    },
    scrollToTop() {
      const myElement = document.getElementById('centerParams');
      myElement.scrollTo({
        top: 0,
        behavior: 'smooth' //可以改为'auto'，实现非平滑滚动
      });
    },
    emptyData() {
      for (let key in this.dataForm) {
        this.dataForm[key] = ''; // 将属性值置为空字符串
      }
    },
    // 提交
    resourceAdd() {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.dataForm.sign = this.getSign(this.dataForm.appId + this.dataForm.mchOrderNo + this.dataForm.amount + this.dataForm.notifyUrl + this.dataForm.appSecret)
          const params = { ...this.dataForm }
          delete params.appSecret
          this.launchAction({ ...params })
          delete params.lang
          this.$eventBus.$emit('addRequestData', params)
        } else {
          this.$message.error("有必填项未填写，请检查后再调用");
        }
      });
    },
    // 添加执行
    async launchAction(params) {
      this.loadingBtn = true;
      try {
        let { data } = await axios.post(config.h5OpenBaseUrl + `/pay/addOrder?lang=${params.lang}`, params)
        // if (data.code == 1) {
        this.$eventBus.$emit('addResponseData', data)
        this.$eventBus.$emit('addDescripData', this.descripData)
        this.init()
        this.$message.success("请求已被响应，请查看响应信息");
        // }
        this.loadingBtn = false;
      }
      catch {
        this.loadingBtn = false;
      }
    },
    getSign(data) {
      const sign = w_md5.hex_md5_32(data) //md5加密生成32位小写签名字符
      return sign
    },
  }
};
</script>

<style lang="scss" scoped>
.centerParams {
  width: 20%;
  height: calc(100vh - 120px);
  overflow-y: auto;
  border: 1px solid #ddd;
  font-size: 14px;
  color: #555;
  line-height: 20px;

  .flag {
    cursor: pointer;

    i {
      margin-left: 10px;
    }
  }

  .title {
    padding: 0 5px 0 15px;

    b {
      color: red;
      margin-right: 2px;
    }
  }
}
</style>
